import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import styled from '@emotion/styled'
import { Controller } from 'react-hook-form'
import { useForm } from '../hooks/useForm'
import * as Yup from 'yup'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import FigmaText from './FigmaText'
import { useState } from 'react'
import iconCompleted from '../../public/iconCompleted.svg'
import iconArrow from '../../public/iconArrow.svg'
import { State } from '../hooks/useAppState'

const PhoneRegExp = /^\+?\d{1,3}\d{7,10}$/
const EMAIL_REGEX = /\S+@\S+\.\S+/

const isValidEmail = (email: string) => {
  try {
    return EMAIL_REGEX.test(email)
  } catch (err) {
    return false
  }
}

type CourseRegistrationFormValues = {
  name: string
  phoneNumber: string
  email: string
  role: 'leader' | 'follower'
  partnerName: string
  partnerPhoneNumber: string
  partnerEmail: string
  partnerRole: 'leader' | 'follower'
}

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Namn är obligatoriskt'),
  phoneNumber: Yup.string()
    .matches(PhoneRegExp, 'Telefonnummer är ogiltigt')
    .required('Telefonnummer är obligatoriskt'),
  email: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  role: Yup.string().required()
})

const ValidationSchemaPartner = Yup.object().shape({
  name: Yup.string().required('Namn är obligatoriskt'),
  phoneNumber: Yup.string()
    .matches(PhoneRegExp, 'Telefonnummer är ogiltigt')
    .required('Telefonnummer är obligatoriskt'),
  email: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  role: Yup.string().required(),
  partnerName: RequiredTextValidation('Namn är obligatoriskt'),
  partnerPhoneNumber: Yup.string()
    .matches(PhoneRegExp, 'Telefonnummer är ogiltigt')
    .required('Telefonnummer är obligatoriskt'),
  partnerEmail: Yup.string().matches(EMAIL_REGEX, 'E-post är ogiltig').required('E-post är obligatorisk'),
  partnerRole: Yup.string().required()
})

type Props = {
  state: State
  registerActiveStep: number
  onNextStep: () => void
  onOpenStepOne: () => void
  onSaveRegistrationData: (data: CourseRegistrationFormValues) => void
}

const CourseRegistrationStepOne = ({
  state,
  registerActiveStep,
  onNextStep,
  onOpenStepOne,
  onSaveRegistrationData
}: Props) => {
  const [hasPartner, setHasPartner] = useState(false)
  const [role, setRole] = useState<'leader' | 'follower'>('leader')
  const [partnerRole, setPartnerRole] = useState<'leader' | 'follower'>('follower')

  const hasMainUser = !!state.name && !!state.email && !!state.phoneNumber

  const formProps = useForm<CourseRegistrationFormValues>(hasPartner ? ValidationSchemaPartner : ValidationSchema, {
    defaultValues: {
      name: '',
      phoneNumber: '',
      email: '',
      role: 'leader',
      partnerName: '',
      partnerPhoneNumber: '',
      partnerEmail: '',
      partnerRole: 'follower'
    }
  })

  const onSubmit = (data: CourseRegistrationFormValues) => {
    onSaveRegistrationData({ ...data, role, partnerRole })
    onNextStep()
  }

  const openStep = () => {
    if (registerActiveStep > 1) {
      onOpenStepOne()
    }
  }

  const changeRole = (isPartnerRole: boolean) => {
    if (isPartnerRole) {
      if (role === 'leader') {
        setRole('follower')
        setPartnerRole('leader')
      } else if (role === 'follower') {
        setRole('leader')
        setPartnerRole('follower')
      }
    } else {
      if (partnerRole === 'leader') {
        setPartnerRole('follower')
        setRole('leader')
      } else if (partnerRole === 'follower') {
        setPartnerRole('leader')
        setRole('follower')
      }
    }
  }

  const handleRemovePartner = () => {
    setHasPartner(false)
    formProps.setValue('partnerName', '')
    formProps.setValue('partnerPhoneNumber', '')
    formProps.setValue('partnerEmail', '')
    formProps.setValue('partnerRole', 'follower')
  }

  return registerActiveStep !== 1 ? (
    <ClosedContainer fullWidth fullHeight fullPadding spacing="16px" onClick={openStep}>
      <Box fullWidth direction="row" align="center" gap="15px">
        {hasMainUser && <img src={iconCompleted} width={24} height={24} />}
        <FigmaText
          textKey={
            registerActiveStep > 1
              ? Texts.registrationHeadersAndStatusHeaderAttendee
              : Texts.registrationHeadersAndStatusHeaderAttendee
          }
        />
      </Box>
    </ClosedContainer>
  ) : (
    <>
      <FormContainer fullWidth>
        <form>
          <Box fullWidth fullPadding spacing="16px">
            <Box direction="row" gap="15px">
              {state.isRegistrationCompleted && <img src={iconCompleted} width={24} height={24} />}
              <FigmaText textKey={Texts.registrationHeadersAndStatusHeaderAttendee} />
            </Box>
            <Box fullWidth top bottom spacing="8px">
              <Controller
                control={formProps.control}
                name="name"
                render={({ field, fieldState }) => (
                  <TextFieldWithLabelForm
                    disabled={state.isRegistrationCompleted}
                    {...field}
                    value={field.value}
                    fullWidth
                    fieldState={fieldState}
                    placeholderTextKey={Texts.registrationAttendeesRegNameHelper}
                  />
                )}
              />
            </Box>
            <Box fullWidth top bottom spacing="8px">
              <Controller
                control={formProps.control}
                name="phoneNumber"
                render={({ field, fieldState }) => (
                  <TextFieldWithLabelForm
                    disabled={state.isRegistrationCompleted}
                    {...field}
                    value={field.value}
                    fullWidth
                    fieldState={fieldState}
                    placeholderTextKey={Texts.registrationAttendeesRegPhoneNumberHelper}
                  />
                )}
              />
            </Box>
            <Box fullWidth top bottom spacing="8px">
              <Controller
                control={formProps.control}
                name="email"
                rules={{
                  validate: (email) => {
                    if (!email) return false
                    return isValidEmail(email)
                  }
                }}
                render={({ field, fieldState }) => (
                  <TextFieldWithLabelForm
                    disabled={state.isRegistrationCompleted}
                    {...field}
                    value={field.value}
                    fullWidth
                    fieldState={fieldState}
                    placeholderTextKey={Texts.registrationAttendeesRegEmailHelper}
                  />
                )}
              />
            </Box>
            <Box direction="row" gap="10px" top bottom spacing="8px">
              <RoleButton
                isSelected={role === 'leader'}
                onClick={role === 'follower' && !state.isRegistrationCompleted ? () => changeRole(false) : undefined}
              >
                <FigmaText textKey={Texts.registrationAttendeesRegRoleLabelLeader} />
              </RoleButton>
              <RoleButton
                isSelected={role === 'follower'}
                onClick={role === 'leader' && !state.isRegistrationCompleted ? () => changeRole(false) : undefined}
              >
                <FigmaText textKey={Texts.registrationAttendeesRegRoleLabelFollower} />
              </RoleButton>
            </Box>
            <Box fullWidth direction="row" align="center" justify="space-between" top spacing="8px">
              {!state.isRegistrationCompleted && (
                <AddPartnerButton onClick={() => setHasPartner(true)}>
                  <FigmaText textKey={Texts.registrationCtactaAddPartner} />
                </AddPartnerButton>
              )}
              {!hasPartner && !state.isRegistrationCompleted && (
                <ContinueButton onClick={formProps.handleSubmit(onSubmit)}>
                  <FigmaText textKey={Texts.registrationCtactaContinue} />
                  <img src={iconArrow} width={16} height={16} />
                </ContinueButton>
              )}
            </Box>
          </Box>
        </form>
      </FormContainer>

      {hasPartner && (
        <FormContainer fullWidth>
          <form>
            <Box fullWidth fullPadding spacing="16px">
              <Box direction="row" gap="15px">
                {state.isRegistrationCompleted && <img src={iconCompleted} width={24} height={24} />}
                <FigmaText textKey={Texts.registrationHeadersAndStatusHeaderPartner} />
              </Box>
              <Box fullWidth top bottom spacing="8px">
                <Controller
                  control={formProps.control}
                  name="partnerName"
                  render={({ field, fieldState }) => (
                    <TextFieldWithLabelForm
                      disabled={state.isRegistrationCompleted}
                      {...field}
                      value={field.value}
                      fullWidth
                      fieldState={fieldState}
                      placeholderTextKey={Texts.registrationAttendeesRegNameHelper}
                    />
                  )}
                />
              </Box>
              <Box fullWidth top bottom spacing="8px">
                <Controller
                  control={formProps.control}
                  name="partnerPhoneNumber"
                  render={({ field, fieldState }) => (
                    <TextFieldWithLabelForm
                      disabled={state.isRegistrationCompleted}
                      {...field}
                      value={field.value}
                      fullWidth
                      fieldState={fieldState}
                      placeholderTextKey={Texts.registrationAttendeesRegPhoneNumberHelper}
                    />
                  )}
                />
              </Box>
              <Box fullWidth top bottom spacing="8px">
                <Controller
                  control={formProps.control}
                  name="partnerEmail"
                  rules={{
                    validate: (email) => {
                      if (!email) return false
                      return isValidEmail(email)
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <TextFieldWithLabelForm
                      disabled={state.isRegistrationCompleted}
                      {...field}
                      value={field.value}
                      fullWidth
                      fieldState={fieldState}
                      placeholderTextKey={Texts.registrationAttendeesRegEmailHelper}
                    />
                  )}
                />
              </Box>
              <Box direction="row" gap="10px" top bottom spacing="8px">
                <RoleButton
                  isSelected={partnerRole === 'leader'}
                  onClick={
                    partnerRole === 'follower' && !state.isRegistrationCompleted ? () => changeRole(true) : undefined
                  }
                >
                  <FigmaText textKey={Texts.registrationAttendeesRegRoleLabelLeader} />
                </RoleButton>
                <RoleButton
                  isSelected={partnerRole === 'follower'}
                  onClick={
                    partnerRole === 'leader' && !state.isRegistrationCompleted ? () => changeRole(true) : undefined
                  }
                >
                  <FigmaText textKey={Texts.registrationAttendeesRegRoleLabelFollower} />
                </RoleButton>
              </Box>
              <Box fullWidth direction="row" align="center" justify="space-between" top spacing="8px">
                {!state.isRegistrationCompleted && (
                  <RemovePartnerButton onClick={handleRemovePartner}>
                    <FigmaText textKey={Texts.registrationCtactaAddPartner} text="Ta bort partner" />
                  </RemovePartnerButton>
                )}
                {!state.isRegistrationCompleted ? (
                  <ContinueButton onClick={formProps.handleSubmit(onSubmit)}>
                    <FigmaText textKey={Texts.registrationCtactaContinue} />
                    <img src={iconArrow} width={16} height={16} />
                  </ContinueButton>
                ) : null}
              </Box>
            </Box>
          </form>
        </FormContainer>
      )}
    </>
  )
}

const ClosedContainer = styled(Box)`
  cursor: pointer;
  max-width: 865px;
  max-height: 70px;
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
`

const FormContainer = styled(Box)`
  max-width: 865px;
  border-radius: 12px;
  background: #fcfcfc;
  box-shadow: 0px 0px 8px 0px #2422bb;
`

const RoleButton = styled(Box)<{ isSelected: boolean }>`
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 12px;
  background-color: ${({ isSelected }) => (isSelected ? '#0E0349' : 'gray')};
`

const AddPartnerButton = styled(Box)`
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 12px;
  background-color: #bae5b7;
`

const ContinueButton = styled.button`
  gap: 16px;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: rgba(59, 89, 236, 1);
`

const RemovePartnerButton = styled(Box)`
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 12px;
  background-color: #ffdbdb;
`

export default CourseRegistrationStepOne
