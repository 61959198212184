import { Discount, DiscountMultiCourse, DiscountUserInput, PriceDiscount } from '../types/DiscountTypes'
import { Activity } from '../types/GrooverTypes'

export const getWinnerMultiCourseDiscount = (registration: Activity, userInput: DiscountUserInput): Discount | null => {
  const { discounts } = registration

  // @ts-ignore
  const multiCourseDiscounts: DiscountMultiCourse[] = discounts?.filter((discount) => discount.type === 'multi_course')

  // @ts-ignore
  return multiCourseDiscounts?.reduce((acc: DiscountMultiCourse | null, discount: DiscountMultiCourse) => {
    if (userInput.coursesAmount >= discount.courses) {
      if (!acc) return discount
      if (discount.courses > (acc as DiscountMultiCourse).courses) return discount
    }
    return acc
  }, null)
}

export const calculateDiscount = (registration: Activity, userInput: DiscountUserInput): PriceDiscount[] => {
  const { discounts: _discounts, price } = registration

  if (userInput.participantsAmount > 2) throw new Error('Max 2 participants are allowed')

  // handle multi_coourse discounts separately, so we apply just one discount of that type, the one matches the highest nunber of courses from user input
  const winnerMultiCourseDiscount = getWinnerMultiCourseDiscount(registration, userInput)

  // mix all discounts together
  const discounts = winnerMultiCourseDiscount
    ? // replace all multi_course discounts with the winner one
      [..._discounts.filter((discount) => discount.type !== 'multi_course'), winnerMultiCourseDiscount]
    : _discounts

  const appliedDiscounts =
    discounts?.reduce((acc: PriceDiscount[], discount: Discount) => {
      let discountAmount = 0
      if (discount.calculationType === 'percentage') {
        discountAmount = (price * discount.value) / 100
      } else {
        discountAmount = discount.value
      }

      const priceDiscount: PriceDiscount = {
        originalPrice: price,
        discountedPrice: price - discountAmount,
        discountUsed: discount,
        appliesTo: discount.appliesTo
      }

      if (discount.type === 'student' && userInput.isStudent) acc.push(priceDiscount)
      if (discount.type === 'senior' && userInput.isSenior) acc.push(priceDiscount)
      if (discount.type === 'multi_course' && userInput.coursesAmount >= discount.courses) acc.push(priceDiscount)
      // couples discount is applied to each participant
      if (discount.type === 'couples' && userInput.participantsAmount > 1) {
        acc.push({ ...priceDiscount, appliesTo: 'mainUser' })
        acc.push({ ...priceDiscount, appliesTo: 'partnerUser' })
      }

      return acc
    }, []) || []

  const noDiscount: PriceDiscount = {
    originalPrice: price,
    discountedPrice: price,
    discountUsed: {
      type: 'none',
      name: 'No discount',
      value: 0,
      calculationType: 'amount',
      appliesTo: 'mainUser'
    },
    appliesTo: 'mainUser'
  }

  return appliedDiscounts.length > 0 ? appliedDiscounts : [noDiscount]
}
