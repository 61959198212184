// @flow
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material'
import StylesProvider from '@mui/styles/StylesProvider'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { SNACK_BAR_PROPS } from '../../../pure-js/libs/Consts'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { Context, useAppStateContext } from '../hooks/useAppState'
import '../libs/Fonts'
import theme from '../libs/theme'
import ErrorBoundaryProvider from './ErrorBoundaryProvider'

import { Toaster } from 'react-hot-toast'
import { OnMountApp } from '../hooks/useOnMountApp'
import { RootPage } from '../pages/SignInPage'
import { Helmet } from './Helmet'
import SandboxPage from './SandboxPage'
import IframePage from '../pages/IframePage'
import PayPage from '../pages/PayPage'
import StaticDemoPage from '../pages/StaticDemoPage'
import RegisterPage from '../pages/RegisterPage'
import { ActivityDetailPage } from '../pages/ActivityDetailPage'
import PaySuccessPage from '../pages/PaySuccessPage'
import Box from './Box'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const context = useAppStateContext()

  return (
    <ErrorBoundaryProvider>
      <StylesProvider injectFirst>
        <SnackbarProvider {...SNACK_BAR_PROPS}>
          <Context.Provider value={context}>
            <BrowserRouter>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Helmet titleTemplate="%s - Groover" />
                  <OnMountApp />
                  <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 2000 }} />
                  <Box fullWidth fullHeight align="center">
                    <Routes>
                      <Route path={RoutePath.ROOT} Component={RootPage} />
                      <Route path={RoutePath.ACTIVITY_DETAIL} Component={ActivityDetailPage}></Route>
                      <Route path={RoutePath.SANDBOX} Component={SandboxPage} />
                      <Route path={RoutePath.IFRAME} Component={IframePage} />
                      <Route path={RoutePath.DEMOPAGE} Component={StaticDemoPage} />
                      <Route path={RoutePath.PAY} Component={PayPage} />
                      <Route path={RoutePath.PAY_SUCCESS} Component={PaySuccessPage} />
                      <Route path={RoutePath.REGISTER} Component={RegisterPage} />
                    </Routes>
                  </Box>
                </ThemeProvider>
              </StyledEngineProvider>
            </BrowserRouter>
          </Context.Provider>
        </SnackbarProvider>
      </StylesProvider>
    </ErrorBoundaryProvider>
  )
}

export default App
