import styled from '@emotion/styled'
import Box from '../components/Box'
import RegisterStepsHeader from '../components/RegisterStepsHeader'
import { useReducer } from 'react'
import { DEFAULT_STATE } from '../hooks/useAppState'
import CourseRegistrationStepOne from '../components/CourseRegistrationStepOne'
import CourseRegistrationStepTwo from '../components/CourseRegistrationStepTwo'
import CourseRegistrationStepThree from '../components/CourseRegistrationStepThree'
import CourseRegistrationStepFour from '../components/CourseRegistrationStepFour'
import { useParams } from 'react-router-dom'
import { doc } from 'firebase/firestore'
import { db } from '../libs/Firebase'
import { useFirestoreQuery } from '../hooks/useFirestoreQuery'
import { Activity } from '../../../pure-js/types/GrooverTypes'

const RegisterPage = () => {
  const { courseId } = useParams<{ courseId: string }>()

  const courseDocRef = courseId ? doc(db, `activities/${courseId}`) : null
  const { data: activity } = useFirestoreQuery<Activity>(courseDocRef!, {
    enabled: !!courseId && courseDocRef !== null,
    fnName: ''
  })

  const [state, dispatcher] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'setActiveStep':
        return { ...prevState, activeRegisterStep: action.payload?.activeRegisterStep }
      case 'saveRegistrationData':
        return {
          ...prevState,
          name: action.payload?.name,
          phoneNumber: action.payload?.phoneNumber,
          email: action.payload?.email,
          role: action.payload?.role,
          partnerName: action.payload?.partnerName,
          partnerPhoneNumber: action.payload?.partnerPhoneNumber,
          partnerEmail: action.payload?.partnerEmail,
          partnerRole: action.payload?.partnerRole
        }
      case 'setIsRegistrationCompleted':
        return { ...prevState, isRegistrationCompleted: true }
      case 'setFinalPrice':
        return { ...prevState, finalPrice: action.payload?.finalPrice }
      default:
        return prevState
    }
  }, DEFAULT_STATE)

  const onNextStep = () => {
    dispatcher({ type: 'setActiveStep', payload: { activeRegisterStep: state.activeRegisterStep + 1 } })
  }

  return (
    <Container fullWidth fullHeight align="flex-end" top bottom gap="16px">
      <RegisterStepsHeader
        isRegistrationCompleted={state.isRegistrationCompleted}
        activeRegisterStep={state.activeRegisterStep}
      />
      <CourseRegistrationStepOne
        state={state}
        registerActiveStep={state.activeRegisterStep}
        onNextStep={onNextStep}
        onOpenStepOne={() => dispatcher({ type: 'setActiveStep', payload: { activeRegisterStep: 1 } })}
        onSaveRegistrationData={(data) => dispatcher({ type: 'saveRegistrationData', payload: data })}
      />
      <CourseRegistrationStepTwo
        registerActiveStep={state.activeRegisterStep}
        activity={activity}
        state={state}
        onNextStep={onNextStep}
        onOpenStepTwo={() => dispatcher({ type: 'setActiveStep', payload: { activeRegisterStep: 2 } })}
        setFinalPrice={(price) => dispatcher({ type: 'setFinalPrice', payload: { finalPrice: price } })}
      />
      <CourseRegistrationStepThree
        activity={activity}
        state={state}
        registerActiveStep={state.activeRegisterStep}
        onNextStep={onNextStep}
        onOpenStepThree={() => dispatcher({ type: 'setActiveStep', payload: { activeRegisterStep: 3 } })}
        onCompleteRegistration={() => dispatcher({ type: 'setIsRegistrationCompleted', payload: true })}
      />
      <CourseRegistrationStepFour
        state={state}
        registerActiveStep={state.activeRegisterStep}
        activity={activity}
        onOpenStepFour={() => dispatcher({ type: 'setActiveStep', payload: { activeRegisterStep: 4 } })}
      />
    </Container>
  )
}

const Container = styled(Box)`
  max-width: 865px;
  padding: 10px;
`

export default RegisterPage
