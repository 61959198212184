import React, { useState } from 'react'
import { Button, Checkbox, Typography, Link, IconButton } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Activity } from '../../../pure-js/types/GrooverTypes'

interface ActivityInfoProps {
  activity: Activity
}

const RegistrationInfo: React.FC<ActivityInfoProps> = ({ activity }) => {
  return (
    <div className="registration-info">
      <div className="header">
        <Typography variant="h5" component="h2" className="header-title">
          {activity.name}
        </Typography>

        {/* <div className="overlay-container">
          <IconButton className="share-button" aria-label="share event">
            <ShareIcon />
            <Typography className="share-button-text">Dela eventet</Typography>
          </IconButton>
          <div className="overlay">
            <Typography variant="body2" className="overlay-text">
              Kommer snart
            </Typography>
          </div>
        </div> */}
      </div>

      {/* Description Text */}
      <Typography variant="body1" className="description" paragraph>
        {activity.description}
      </Typography>

      {/* Button with Arrow Icon */}
      <div>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          className="action-button"
          href={`/register/${activity.id}`}
        >
          Anmäl mig/oss
        </Button>
      </div>
    </div>
  )
}

export default RegistrationInfo
