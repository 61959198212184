import { Elements } from '@stripe/react-stripe-js'

import getStripe from '../libs/getStripe'
import StripeCheckoutForm from '../components/StripeCheckoutForm'

import * as stripeJs from '@stripe/stripe-js'
import Box from '../components/Box'
import { useEffect, useState } from 'react'
import { createStripePaymentIntent } from '../libs/CloudFunctionsApiHandler'
import { CreateStripePaymentIntentWebRequest } from '../../../pure-js/types/StripeTypes'
import useAppState from '../hooks/useAppState'
import { useParams } from 'react-router-dom'

const PayPage = () => {
  const { enrollmentId } = useParams<{ enrollmentId: string }>()

  const [clientSecret, setClientSecret] = useState('')
  const [isError, setIsError] = useState(false)
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('')
  const { state } = useAppState()

  useEffect(() => {
    if (!enrollmentId) return
    const fetchClientSecret = async () => {
      const request: CreateStripePaymentIntentWebRequest = {
        enrollmentId: enrollmentId
      }
      const result = await createStripePaymentIntent(request, state)
      if ('isError' in result) {
        setIsError(true)
        console.error('Error creating payment intent:', result)
        return
      }

      setClientSecret(result.clientSecret)
      setAmount((result.amount / 100).toFixed(2)) // original amount is in cents
      setCurrency(result.currency.toUpperCase())
    }

    fetchClientSecret()
  }, [])

  const options: stripeJs.StripeElementsOptions = {
    clientSecret: clientSecret
  }

  return (
    <Box width="650px" fullHeight>
      {!enrollmentId && <h1>No enrollment ID</h1>}
      {!isError && !clientSecret && <h1>Initializing...</h1>}
      {isError && <h1>Error initializing payment</h1>}
      {enrollmentId && clientSecret && (
        <>
          <h1>
            Pay {amount}
            {currency}
          </h1>
          <Elements stripe={getStripe()} options={options}>
            <StripeCheckoutForm />
          </Elements>
        </>
      )}
    </Box>
  )
}

export default PayPage
