import React from 'react'
import { tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import Box from './Box'
import TextFieldForm, { TextFieldFormProps } from './TextFieldFormDeprecated'
import styled from '@emotion/styled'

const TextFieldWithLabelFormComponent = (props: TextFieldFormProps, ref) => {
  const { labelTextKey, fullWidth, labelText, ...textFieldProps } = props
  return (
    <Box fullWidth={fullWidth}>
      {labelTextKey && <FigmaText textKey={labelTextKey} text={labelText} />}
      <Box top spacing={tinySpacing} fullWidth>
        <StyledTextFieldForm {...textFieldProps} ref={ref} fullWidth />
      </Box>
    </Box>
  )
}

const StyledTextFieldForm = styled(TextFieldForm)`
  background-color: rgba(99, 20, 215, 0.05);
  border-radius: 12px;
  border: none !important;

  .css-1ll44ll-MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-radius: 12px;
  }

  .css-op7uzz-MuiFormControl-root-MuiTextField-root-BaseTextField-StyledTextFieldForm,
  .css-1sdw9wy-MuiInputBase-root-MuiOutlinedInput-root,
  .css-kt6r3a-MuiFormControl-root-MuiTextField-root-BaseTextField-StyledTextFieldForm {
    border-radius: 12px !important;
  }

  .css-1pzfmz2-MuiInputBase-input-MuiOutlinedInput-input::placeholder,
  .css-1sdw9wy-MuiInputBase-root-MuiOutlinedInput-root::placeholder {
    color: rgba(36, 34, 187, 1);
  }
`

export const TextFieldWithLabelForm = React.forwardRef(TextFieldWithLabelFormComponent)
