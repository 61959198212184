enum RoutePath {
  ROOT = '/',
  SANDBOX = '/sandbox',
  IFRAME = '/iframe',
  DEMOPAGE = '/demo',
  PAY = '/pay/:enrollmentId',
  PAY_SUCCESS = '/pay-success',
  REGISTER = '/register/:courseId',
  DASHBOARD = '/dashboard',
  ENROLLMENTS = '/enrollments/:clientId/:activityId',
  PROFILE = '/profile',
  INVITE = '/invite',
  ACTIVITY_DETAIL = '/activity/:activityId'
}

export default RoutePath
