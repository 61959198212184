export enum Collections {
  USERS = 'users',
  CLIENTS = 'clients',
  CLIENTS_PRIVATE = 'clients_private',
  COURSE_ADMINISTRATORS = 'course_administrators',
  ENROLLMENT_USERS = 'enrollment_users',
  ENROLLMENTS = 'enrollments',
  EVENTS = 'events',
  ACTIVITIES = 'activities'
}
