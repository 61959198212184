import styled from '@emotion/styled'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { useState } from 'react'
import { saveUserRegistration } from '../libs/CloudFunctionsApiHandler'
import { Activity, PaymentMethod } from '../../../pure-js/types/GrooverTypes'
import { State } from '../hooks/useAppState'
import Spinner from './Spinner'
import iconCompleted from '../../public/iconCompleted.svg'
import iconArrow from '../../public/iconArrow.svg'

type Props = {
  registerActiveStep: number
  onNextStep: () => void
  onOpenStepThree: () => void
  onCompleteRegistration: () => void
  state: State
  activity?: Activity
}

const CourseRegistrationStepThree = ({
  registerActiveStep,
  onNextStep,
  onOpenStepThree,
  onCompleteRegistration,
  state,
  activity
}: Props) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const hasPartner = state.partnerName && state.partnerEmail && state.partnerPhoneNumber && state.partnerRole
  const hasMainUser = !!state.name && !!state.email && !!state.phoneNumber

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(event.target.checked)
  }

  const onFinally = () => {
    setIsLoading(false)
    onCompleteRegistration()
  }

  const handleContinue = () => {
    if (isCheckboxChecked) {
      if (activity?.clientId) {
        setIsLoading(true)
        if (!hasPartner) {
          saveUserRegistration(
            {
              clientId: activity?.clientId,
              mainUser: {
                name: state.name,
                email: state.email,
                phone: state.phoneNumber,
                type: state.role
              },
              activityIds: [activity.id],
              discountUserInput: { coursesAmount: activity.price, participantsAmount: 1 },
              paymentInput: [{ user: 'mainUser', method: PaymentMethod.BankTransfer }]
            },
            state
          )
            .then(onNextStep)
            .catch((err) => console.log(err))
            .finally(onFinally)
        } else {
          saveUserRegistration(
            {
              clientId: activity?.clientId,
              mainUser: {
                name: state.name,
                email: state.email,
                phone: state.phoneNumber,
                type: state.role
              },
              partnerUser: {
                name: state.partnerName as string,
                email: state.partnerEmail as string,
                phone: state.partnerPhoneNumber as string,
                type: state.partnerRole
              },
              activityIds: [activity.id],
              discountUserInput: { coursesAmount: activity.price, participantsAmount: 2 },
              paymentInput: [{ user: 'mainUser', method: PaymentMethod.BankTransfer }]
            },
            state
          )
            .then(onNextStep)
            .catch((err) => console.log(err))
            .finally(onFinally)
        }
      }
    }
  }

  return registerActiveStep !== 3 ? (
    <ClosedContainer fullWidth fullHeight fullPadding spacing="16px" onClick={onOpenStepThree}>
      <Box fullWidth direction="row" align="center" gap="15px">
        {(registerActiveStep > 3 || state.isRegistrationCompleted) && (
          <img src={iconCompleted} width={24} height={24} />
        )}
        <FigmaText
          textKey={
            registerActiveStep > 1
              ? Texts.registrationHeadersAndStatusHeaderSummaryAndApproval
              : Texts.registrationHeadersAndStatusHeaderSummaryAndApproval
          }
        />
      </Box>
    </ClosedContainer>
  ) : (
    <OpenContainer fullWidth fullPadding spacing="16px" gap="16px">
      <Box gap="16px">
        <Box direction="row" gap="15px">
          {state.isRegistrationCompleted && <img src={iconCompleted} width={24} height={24} />}
          <FigmaText textKey={Texts.registrationHeadersAndStatusHeaderSummaryAndApproval} />
        </Box>
        <FigmaText textKey={Texts.registrationApprovalInfoApprovalPositive} />
      </Box>

      <Box gap="8px">
        <Box direction="row" align="center" gap="8px">
          <span style={{ color: '#28a745', fontSize: '18px' }}>✔</span>
          <FigmaText textKey={Texts.registrationApprovalInfoApprovalStep2} />
        </Box>
        <Box direction="row" align="center" gap="8px">
          <span style={{ color: '#28a745', fontSize: '18px' }}>✔</span>
          <FigmaText textKey={Texts.registrationApprovalInfoApprovalStep3} />
        </Box>
      </Box>

      <Box direction="row" align="center" gap="8px">
        <input
          type="checkbox"
          id="accountApproval"
          disabled={state.isRegistrationCompleted}
          checked={isCheckboxChecked}
          onChange={handleCheckboxChange}
        />
        <Box
          direction="row"
          align="center"
          gap="4px"
          style={{
            display: 'flex',
            flexWrap: 'wrap', // Allows the children to wrap when they exceed the parent's width
            width: '100%', // Makes the parent take up full width
            alignItems: 'center' // Ensures vertical alignment of children
          }}
        >
          <FigmaText textKey={Texts.registrationApprovalLegalIApprove} />
          <Link onClick={() => window.open('http://www.coursely.se/privacy-policy', '_blank')}>
            <FigmaText textKey={Texts.registrationApprovalLegalPrivacy} />
          </Link>
          <FigmaText textKey={Texts.registrationApprovalLegalAnd} />
          <Link onClick={() => window.open('http://www.coursely.se/registration-rules-and-policy', '_blank')}>
            <FigmaText textKey={Texts.registrationApprovalLegalCondition} />
          </Link>
        </Box>
      </Box>

      <Box align="center">
        {!state.isRegistrationCompleted ? (
          <ContinueButton onClick={handleContinue} disabled={!isCheckboxChecked || !hasMainUser}>
            <FigmaText textKey={Texts.registrationCtactaContinue} />
            <img src={iconArrow} width={16} height={16} />
            {isLoading ? <Spinner /> : null}
          </ContinueButton>
        ) : null}
      </Box>
    </OpenContainer>
  )
}

const ClosedContainer = styled(Box)`
  cursor: pointer;
  max-width: 865px;
  max-height: 70px;
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
`

const OpenContainer = styled(Box)`
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
  box-shadow: 0px 0px 8px 0px #2422bb;
`

const Link = styled(Box)`
  cursor: pointer;
`

const ContinueButton = styled.button<{ disabled: boolean }>`
  gap: 16px;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: ${({ disabled }) => (disabled ? '#C9C9C9' : 'rgba(59, 89, 236, 1)')};
`

export default CourseRegistrationStepThree
