// using @stripe/stripe-js/pure instead of @stripe/stripe-js allows to lazy load Stripe in the browser
import { loadStripe } from '@stripe/stripe-js/pure'

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      'pk_test_51Pk54gHDiZBVdjpWE5rbWfrtasMsZU8Y62NpRSbigFKp7wRxZ5IfIXLroaiBJ2r5kgwUGsPVf5ehqJLf7524dvtH00SK8GHFxn'
    )
  }
  return stripePromise
}

export default getStripe
