import styled from '@emotion/styled'
import React from 'react'
import divider from '../../../assets/images/divider.svg'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'

type StepsHeaderProps = {
  isRegistrationCompleted: boolean
  activeRegisterStep: number
}

const RegisterStepsHeader = ({ activeRegisterStep, isRegistrationCompleted }: StepsHeaderProps) => {
  const steps = [
    {
      textKey: Texts.registrationProgressProgressPersonal,
      isActive: activeRegisterStep === 1,
      isCompleted: activeRegisterStep > 1
    },
    {
      textKey: Texts.registrationProgressProgressCourses,
      isActive: activeRegisterStep === 2,
      isCompleted: activeRegisterStep > 2
    },
    {
      textKey: Texts.registrationProgressProgressApproved,
      isActive: activeRegisterStep === 3,
      isCompleted: activeRegisterStep > 3
    },
    {
      textKey: Texts.registrationProgressProgressAllDone,
      isActive: activeRegisterStep === 4,
      isCompleted: activeRegisterStep === 4
    }
  ]

  return (
    <StepsHeader fullWidth direction="row" align="center" justify="space-around" fullPadding spacing="16px">
      {steps.map((step, index) => (
        <React.Fragment key={`header-${index}`}>
          <Box direction="row" align="center" gap="5px">
            {!isRegistrationCompleted ? (
              <StepCircle align="center" justify="center" isActive={step.isActive} isCompleted={step.isCompleted}>
                {index + 1}
              </StepCircle>
            ) : (
              <StepCircle align="center" justify="center" isActive={false} isCompleted={true}>
                {index + 1}
              </StepCircle>
            )}
            {!isRegistrationCompleted ? (
              <TextBox isActive={step.isActive} isCompleted={step.isCompleted}>
                <FigmaText textKey={step.textKey} />
              </TextBox>
            ) : (
              <TextBox isActive={false} isCompleted={true}>
                <FigmaText textKey={step.textKey} />
              </TextBox>
            )}
          </Box>
          {index < steps.length - 1 && <StepDivider src={divider} />}
        </React.Fragment>
      ))}
    </StepsHeader>
  )
}

const StepsHeader = styled(Box)`
  max-height: 65px;
  background: #f3f5fa;
  border-radius: 4px;
`

const StepCircle = styled(Box)<{ isActive: boolean; isCompleted: boolean }>`
  flex-shrink: 0; /* Prevents collapsing */
  width: 30px;
  height: 30px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  background-color: ${({ isActive, isCompleted }) =>
    isActive ? 'rgba(106, 171, 55, 0.3)' : isCompleted ? '#6AAB37' : '#d9d9d9'};
`

const TextBox = styled(Box)<{ isActive: boolean; isCompleted: boolean }>`
  opacity: ${({ isActive, isCompleted }) => (isActive ? 1 : isCompleted ? 1 : 0.5)};
  @media (max-width: 767px) {
    display: none;
  }
`

const StepDivider = styled.img`
  width: 100px;
  height: 30px;
  @media (max-width: 767px) {
    display: none;
  }
`

export default RegisterStepsHeader
