import './App.css'
import App from './components/App'
import { createRoot } from 'react-dom/client'
import onUnhandledPromiseRejection from './libs/onUnhandledPromiseRejection'

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)
  root.render(<App />)
}

window.addEventListener('unhandledrejection', ({ reason: error }) => onUnhandledPromiseRejection(error))
