import React from 'react'
import { useAppStateContext } from '../hooks/useAppState'

const SignInPage: React.FC = () => {
  const { state } = useAppStateContext()
  const { user } = state

  return (
    <div>
      <h1>Welcome {user?.name} - You are logged in</h1>
    </div>
  )
}

export const RootPage = SignInPage
export default SignInPage
