import Box from '../components/Box'

const PaySuccessPage = () => {
  return (
    <Box fullWidth fullHeight>
      <Box>Payment successful</Box>
    </Box>
  )
}

export default PaySuccessPage
