import * as SlackHelper from './SlackHelper'

const IGNORED_ERRORS = [
  'Connection to Indexed Database server lost. Refresh the page to try again',
  'Failed to get document because the client is offline',
  'UnknownError Connection to Indexed Database server lost. Refresh the page to try again'
]

export const captureException = (err, user?) => {
  if (IGNORED_ERRORS.some((text) => err.message.includes(text))) return
  SlackHelper.error(err, user)
}

export const captureAndNotifyError = (err: Error) => {
  captureException(err)
  // ToastHelper.error()
}
