import { SlackChannels, SlackWebHooks } from '../../pure-js/enums/SlackChannels.js'
import { POST } from '../../pure-js/libs/Consts.js'

export const slack = (mrkDown: string, channel: SlackChannels, extra?: any): Promise<any> => {
  return fetch(SlackWebHooks.OPS_ERRORS_WEB, {
    method: POST,
    body: JSON.stringify({
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: mrkDown
          }
        }
      ],
      channel,
      ...extra
    })
  }).catch(
    () => ({}) // if slack borks then unhandled promise rejection will throw slack messag => infinite loop => slack turns off integration
  )
}
