export enum CloudFunction {
  createAuthenticationTokenForLoginToken = 'createAuthenticationTokenForLoginToken',
  createLoginWithCodeToken = 'createLoginWithCodeToken',
  inviteUsers = 'inviteUsers',
  signUpUser = 'signUpUser',
  joinTeam = 'joinTeam',
  deleteUser = 'deleteUser',
  triggerReflectionEmail = 'triggerReflectionEmail',
  setCustomUserClaimsForLoggedInUser = 'setCustomUserClaimsForLoggedInUser',
  createStripePaymentIntent = 'createStripePaymentIntent',
  getActivitiesForClient = 'getActivitiesForClient',
  getEnrollmentsForClient = 'getEnrollmentsForClient',
  upsertEnrollment = 'upsertEnrollment',
  getEnrollmentsForPeriod = 'getEnrollmentsForPeriod',
  sendEnrollmentEmail = 'sendEnrollmentEmail',
  getObfuscatedEnrollment = 'getObfuscatedEnrollment',
  saveUserRegistration = 'saveUserRegistration'
}
