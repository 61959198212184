import { useParams } from 'react-router-dom'
import { Client, Activity } from '../../../pure-js/types/GrooverTypes'

import './ActivityDetailPage.css' // Import the CSS file
import RegistrationInfo from '../components/RegistrationInfo'
import RegistrationPrice from '../components/RegistrationPrice'
import RegistrationOccurences from '../components/RegistrationOccurences'
import RegistrationLocation from '../components/RegistrationLocation'
import { useEffect, useState } from 'react'
import { getActivity, getClient } from '../libs/DBApiHandler'
import { set } from 'date-fns'

export const ActivityDetailPage = () => {
  const { activityId } = useParams<{ activityId: string }>()
  const [activity, setActivity] = useState<Activity | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [client, setClient] = useState<Client | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        setIsLoading(true)
        if (!activityId) {
          setError('Activity ID is missing')
          setIsLoading(false)
          return
        }

        const activityData = await getActivity(activityId)
        if (activityData) {
          setActivity(activityData)

          if (activityData.clientId) {
            const clientData = await getClient(activityData.clientId)
            if (clientData) {
              setClient(clientData)
            } else {
              setError('Client not found')
            }
          }
        } else {
          setError('Activity not found')
        }
      } catch (err) {
        setError('Failed to fetch data')
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchActivity()
  }, [activityId])

  const isActivityValid = activity && activity.name

  return (
    <div className="master-container">
      {isLoading ? (
        <div className="spinner">Loading...</div>
      ) : error ? (
        <p className="error-state">{error}</p>
      ) : isActivityValid ? (
        <>
          <div className="left-container">
            <RegistrationInfo activity={activity} />
            <RegistrationPrice activity={activity} />
          </div>
          <div className="right-container">
            {client && <RegistrationLocation customer={client} />}
            <RegistrationOccurences activity={activity} />
          </div>
        </>
      ) : (
        <p className="empty-state">No course or event found</p>
      )}
    </div>
  )
}
