import styled from '@emotion/styled'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { Activity, Client } from '../../../pure-js/types/GrooverTypes'
import { getClient } from '../libs/DBApiHandler'
import { useEffect, useState } from 'react'
import { State } from '../hooks/useAppState'
import iconCompleted from '../../public/iconCompleted.svg'

type Props = {
  registerActiveStep?: number
  activity?: Activity
  state: State
  onOpenStepFour: () => void
}

const CourseRegistrationStepFour = ({ registerActiveStep, activity, state, onOpenStepFour }: Props) => {
  const [client, setClient] = useState<Client | null>(null)

  useEffect(() => {
    const fetchClient = async () => {
      if (activity && activity.clientId) {
        const clientData = await getClient(activity.clientId)
        if (clientData) {
          setClient(clientData)
        }
      }
    }
    fetchClient()
  }, [activity])

  return registerActiveStep !== 4 ? (
    <ClosedContainer
      fullWidth
      fullHeight
      fullPadding
      spacing="16px"
      onClick={state.isRegistrationCompleted ? onOpenStepFour : undefined}
    >
      <Box fullWidth direction="row" align="center" gap="15px">
        {state.isRegistrationCompleted && <img src={iconCompleted} width={24} height={24} />}
        <FigmaText textKey={Texts.registrationHeadersAndStatusHeaderFinalSummary} />
      </Box>
    </ClosedContainer>
  ) : (
    <OpenContainer fullWidth fullPadding spacing="16px" backgroundColor="#f9f9f9" gap="8px">
      <Box direction="row" gap="15px">
        {state.isRegistrationCompleted && <img src={iconCompleted} width={24} height={24} />}
        <FigmaText textKey={Texts.registrationHeadersAndStatusHeaderFinalSummary} />
      </Box>
      <YellowBox fullWidth fullPadding spacing="16px" backgroundColor="#ffecb3" align="center">
        <FigmaText textKey={Texts.registrationPaymentWaitingBankInfo} />
        <FigmaText
          textKey={Texts.registrationPaymentWaitingBankInfo2}
          text={
            activity?.price
              ? `Betala ${state?.finalPrice} till BankGiro ${client?.bankGiro} med ditt kompletta för- och efternamn för att slutföra anmälningen.`
              : ''
          } // TODO: Fix terms
        />
      </YellowBox>
    </OpenContainer>
  )
}

const ClosedContainer = styled(Box)`
  cursor: pointer;
  max-width: 865px;
  max-height: 70px;
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
`

const OpenContainer = styled(Box)`
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
  box-shadow: 0px 0px 8px 0px #2422bb;
`

const YellowBox = styled(Box)`
  background-color: #ffecb3;
  border-radius: 12px;
`

export default CourseRegistrationStepFour
