import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import RegistrationList from '../components/RegistrationList'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import { getActivitiesByCustomerId } from '../libs/DBApiHandler'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import '../components/CourseList.css' // Import the CSS file

const IframePage = () => {
  const [data, setData] = useState<Activity[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  // Function to get query parameters
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search)
  }

  const queryParams = getQueryParams(location.search)
  const customerId = queryParams.get('customerId')

  // This function sends the iframe content's height to the parent window
  const sendHeight = () => {
    const height = document.body.scrollHeight // Get the current scroll height of the content
    console.log('Sending height:', height)
    window.parent.postMessage({ iframeHeight: height }, '*') // Send the height to the parent
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (customerId) {
          const registrations = await getActivitiesByCustomerId(customerId)
          console.log('registrations: ', registrations)
          setData(registrations)
        }
      } catch (error) {
        console.error('Error fetching data: ', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()

    // Add a resize event listener to send the updated height when the window is resized
    const resizeListener = () => sendHeight()
    window.addEventListener('resize', resizeListener)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [customerId])

  // Function to handle content change (for example, if a section is expanded/collapsed)
  const handleContentChange = () => {
    sendHeight() // Trigger re-sending the height after content change
  }

  return (
    <div className="iframe-container">
      {loading ? (
        <Box className="loading-container">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {data.length === 0 ? (
            <Box className="no-courses-container">
              <p>Inga kurser hittade</p>
            </Box>
          ) : (
            <RegistrationList courses={data} handleContentChange={handleContentChange} />
          )}
        </>
      )}
    </div>
  )
}

export default IframePage
