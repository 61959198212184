import { Typography } from '@mui/material'
import React from 'react'
import { Activity } from '../../../pure-js/types/GrooverTypes'

interface ActivityInfoProps {
  activity: Activity
}

const RegistrationPrice: React.FC<ActivityInfoProps> = ({ activity }) => {
  return (
    <div className="segment">
      <div className="header">
        <Typography variant="h5" component="h2" className="header-title">
          Pris
        </Typography>
      </div>
      <p>{activity.price ? activity.price / 100 + ' SEK' : 'Gratis'}</p>
      {/* TODO: Term handling */}
      {activity.discounts &&
        activity.discounts
          .filter((discount) => discount.name === 'Couples discount')
          .map((discount, index) => (
            <p key={index} className="discount">
              {discount.value / 100} SEK / person rabatt vid paranmälan
            </p> // TODO: Term handling
          ))}
    </div>
  )
}

export default RegistrationPrice
