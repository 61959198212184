import React from 'react'
import { Typography, IconButton } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import { Client } from '../../../pure-js/types/GrooverTypes'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

interface RegistrationLocationProps {
  customer: Client
}

const RegistrationLocation: React.FC<RegistrationLocationProps> = ({ customer }) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyDqP2HzJj8g82cwrNDF0YEoVhh26KwDUZs'

  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  }

  const center = {
    lat: 57.776129,
    lng: 14.180453
  }

  return (
    <div className="registration-info">
      <div className="header">
        <Typography variant="h5" component="h2" className="header-title">
          Plats
        </Typography>
        <IconButton
          className="share-button"
          aria-label="share event"
          onClick={() => window.open('https://maps.app.goo.gl/tz9xYfSo3bAt27bs5', '_blank')}
        >
          <ShareIcon />
          <Typography className="share-button-text">Öppna i Google Maps</Typography>
        </IconButton>
      </div>
      <Typography variant="body1">
        <strong>{customer.name}</strong>
        <br />
        {customer.address}
      </Typography>

      {googleMapsApiKey && (
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={17}>
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      )}
    </div>
  )
}

export default RegistrationLocation
