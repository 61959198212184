import { Discount, DiscountUserInput } from './DiscountTypes'
import { BaseObject } from './types'

export type InternalCustomerData = BaseObject & {
  clientId: string
  stripeCustomerId: string
}

export enum PaymentMethod {
  Stripe = 'Stripe',
  BankTransfer = 'BankTransfer',
  Swish = 'Swish',
  Cash = 'Cash',
  Klarna = 'Klarna'
}

export type PaymentMethodFee = {
  method: PaymentMethod
  fee: number
}

export type Client = BaseObject & {
  name: string
  address: string
  contactEmail: string
  bankGiro: string
  homepage: string
  orgNo: string
  status: 'active' | undefined
  paymentMethodFees: PaymentMethodFee[]
}

export type ClientPrivate = {
  clientId: string
  stripe?: {
    payment_method_types: string[]
    public_key?: string
    private_key?: string
  }
}

// An activity is a course or an one-time event
export type Activity = BaseObject & {
  clientId: string // The school that uses the system
  type: 'Course' | 'Occasion'
  status: 'active' | 'planned' | 'cancelled' | 'completed'
  name: string
  description: string
  length: number // In minutes ex. 55 min
  noOfOccasions: number // 10 for a course
  occations: OccationInstance[]
  period: string // ex: “Q4 2024”
  ageGroup: string // ex. “18+”
  level: string
  price: number
  VAT: number
  currency: string
  discounts: Discount[]
  startDate: string // precise date (YYYY-MM-DD) when the course starts
  dayOfWeek: number // ex: 18.00
  time: string
  place: string // Text for now
  activityType: 'Couple' | 'Single'
  maxAllowedUnbalance: number // How many leaders/followers can be unbalanced
  style: string
  substyle: string
  colorCode: string
  reserveListActive: boolean
  approvalRequired: boolean
  maxAllowedParticipants: number // How many activities can be made before the course is full
  allowDropin: boolean
}

export type OccationInstance = {
  startDate: string // ex. 2024-10-01 17:00
  endDate: string // ex. 2024-12-01 18:00
  instructors: string[] // List of teacher ids e.g userIds
}

// used to send user data from frontend to backend
export type EnrollmentUserRequest = {
  name: string
  email: string
  phone: string
  type: 'leader' | 'follower'
}

export type EnrollmentPaymentInputRequest = {
  user: 'mainUser' | 'partnerUser'
  method: PaymentMethod
}

export type EnrollmentRequest = {
  clientId: string
  mainUser: EnrollmentUserRequest
  partnerUser?: EnrollmentUserRequest
  activityIds: string[]
  discountUserInput: DiscountUserInput
  paymentInput: EnrollmentPaymentInputRequest[]
}

// database representation of a user in a course
export type EnrollmentUser = BaseObject &
  Omit<EnrollmentUserRequest, 'type'> & {
    stripeCustomerId?: string
  }

export type Enrollment = BaseObject & {
  clientId: string
  mainUser: EnrollmentUserRequest & { id: string }
  partnerUser?: EnrollmentUserRequest & { id: string }
  activities: Activity[]
  status: 'pending' | 'confirmed' | 'active' | 'cancelled' | 'deleted'
  discountUserInput: DiscountUserInput
  payments: Payment[] // payments are future operations when doing payments or marking as paid
  priceDetails: PriceDetails[] // Breakdown of the price
}

// if refund, value is negative
export type Payment = {
  currency: string
  totalAmount: number // Including VAT
  paymentDate?: string // TODO remove, use stripe payment object instead
  paymentRef?: string // ex. Stripe payment id
  paymentMethod: PaymentMethod
  source: 'stripe' | 'manual'
  type: 'pay' | 'refund'
}

export type PriceDetails = {
  activityId: string
  originalPrice: number // price before discount; course price
  totalDiscountPrice: number // discount price from all discounts
  finalPrice: number // originalPrice - totalDiscountPrice
  paymentMethod: PaymentMethod
  administrationFee: number // derives from the payment method selected
  discounts: Discount[] // Discounts used
  appliesTo: string // which user the price applies to (mainUser or partnerUser)
}
