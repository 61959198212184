import * as auth from 'firebase/auth'
import React, { useContext } from 'react'
import { User } from '../../../pure-js/types/User'
import { StorageKey } from './useLocalStorageItemHelper'
import { useSessionState } from './useSessionState'
import { useStorageItem } from './useStorageItem'

export type State = {
  user: User
  firebaseUser?: auth.User
  isRegistrationCompleted: boolean
  activeRegisterStep: number
  name: string
  phoneNumber: string
  email: string
  role: 'leader' | 'follower'
  partnerName?: string
  partnerPhoneNumber?: string
  partnerEmail?: string
  partnerRole: 'leader' | 'follower'
  finalPrice: string
}

export const DEFAULT_STATE: State = {
  user: { id: '' } as User,
  activeRegisterStep: 1,
  isRegistrationCompleted: false,
  name: '',
  phoneNumber: '',
  email: '',
  role: 'leader',
  partnerName: '',
  partnerPhoneNumber: '',
  partnerEmail: '',
  partnerRole: 'follower',
  finalPrice: ''
}

export type AppContext = {
  state: State
  setState: (state: State) => unknown
}
export const Context = React.createContext<AppContext>({
  state: DEFAULT_STATE,
  setState: () => ({})
})

const IGNORED_KEYS: Array<keyof State> = []

export const useAppStateContext = () => {
  const { storageItem: state, setStorageItem: setState } = useStorageItem(StorageKey.STATE, DEFAULT_STATE, {
    ignoredKeys: IGNORED_KEYS
  })

  const session = useSessionState(state)

  return { state: { ...state, ...session }, setState }
}

export default function useAppState() {
  return useContext(Context)
}

export const useUserId = () => useAppState().state.user.id
