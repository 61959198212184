import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const Spinner: React.FC = () => {
  return <StyledSpinner />
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledSpinner = styled.div`
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`

export default Spinner
