import styled from '@emotion/styled'
import Box from './Box'
import FigmaText from './FigmaText'
import { Texts } from '../../../pure-js/libs/Texts'
import { Activity } from '../../../pure-js/types/GrooverTypes'
import { State } from '../hooks/useAppState'
import { calculateDiscount } from '../../../pure-js/libs/DiscountEngineHelper'
import iconCompleted from '../../public/iconCompleted.svg'
import iconArrow from '../../public/iconArrow.svg'
import { useEffect } from 'react'

type Props = {
  registerActiveStep: number
  onNextStep: () => void
  onOpenStepTwo: () => void
  activity?: Activity
  state: State
  setFinalPrice: (price: string) => void
}

const CourseRegistrationStepTwo = ({
  registerActiveStep,
  onNextStep,
  onOpenStepTwo,
  activity,
  state,
  setFinalPrice
}: Props) => {
  let discounts

  const hasMainUser = !!state.name && !!state.email && !!state.phoneNumber

  const hasPartner =
    !!state?.partnerName && !!state?.partnerEmail && !!state?.partnerPhoneNumber && !!state?.partnerRole

  const participantsAmount = hasPartner ? 2 : 1
  if (activity && registerActiveStep === 2) {
    discounts = calculateDiscount(activity, { coursesAmount: 1, participantsAmount })
  }

  if (!discounts) return

  const mainUserDiscount = discounts.find((d) => d.appliesTo === 'mainUser')
  const partnerDiscount = discounts.find((d) => d.appliesTo === 'partnerUser')

  const shouldShowDiscount = mainUserDiscount && mainUserDiscount.originalPrice !== mainUserDiscount.discountedPrice
  const shouldShowPartnerDiscount = partnerDiscount && partnerDiscount.originalPrice !== partnerDiscount.discountedPrice

  const finalPrice = discounts.reduce((total, discount) => total + discount.discountedPrice, 0) / 100

  useEffect(() => {
    if (finalPrice) setFinalPrice(finalPrice + ' SEK')
  }, [])

  return registerActiveStep !== 2 ? (
    <ClosedContainer fullWidth fullHeight fullPadding spacing="16px" onClick={onOpenStepTwo}>
      <Box fullWidth direction="row" align="center" gap="15px">
        {(registerActiveStep > 1 || state.isRegistrationCompleted) && hasMainUser && (
          <img src={iconCompleted} width={24} height={24} />
        )}
        <FigmaText
          textKey={
            registerActiveStep > 1
              ? Texts.registrationHeadersAndStatusHeaderSelectedCourses
              : Texts.registrationHeadersAndStatusHeaderSelectedCourses
          }
        />
      </Box>
    </ClosedContainer>
  ) : (
    <OpenContainer fullWidth fullHeight fullPadding spacing="16px">
      <Box fullWidth direction="row" align="center" gap="15px">
        <Box direction="row" gap="15px">
          {state.isRegistrationCompleted && <img src={iconCompleted} width={24} height={24} />}
          <FigmaText textKey={Texts.registrationHeadersAndStatusHeaderSelectedCourses} />
        </Box>
        <StepCircle align="center" justify="center">
          {1}
        </StepCircle>
      </Box>
      <SelectedCourses fullWidth direction="row" align="flex-start" justify="space-between">
        <Box top fullWidth direction="row" align="center" gap="30px">
          <FigmaText textKey={Texts.componentsKpIsKpiPaidLabel} text={activity?.name} />
          <FigmaText textKey={Texts.componentsKpIsKpiPaidLabel} text={activity?.startDate} />
          <FigmaText textKey={Texts.componentsKpIsKpiPaidLabel} text={activity?.time} />
        </Box>
        <PriceContainer fullPadding spacing="16px">
          <Box fullWidth>
            <FigmaText textKey={Texts.componentsKpIsKpiSecondary} text={state?.name} />
            <CourseContainer fullWidth fullPadding spacing="16px">
              <Box fullWidth direction="row" justify="space-between">
                <FigmaText textKey={Texts.componentsKpIsKpiSecondary} text={activity?.name} />
                <Box direction="row" gap="4px">
                  <FigmaText
                    textKey={Texts.componentsKpIsKpiSecondary}
                    text={String(discounts[0]?.originalPrice / 100)}
                  />
                  <FigmaText textKey={Texts.componentsKpIsKpiSecondary} text="SEK" />
                </Box>
              </Box>
              {shouldShowDiscount && (
                <>
                  <Box fullWidth direction="row" justify="space-between" top spacing="8px">
                    <FigmaText textKey={Texts.componentsPricingControlCoupleDiscountRow} />
                    <Discount fullPadding spacing="2px">
                      <FigmaText
                        textKey={Texts.codeTablesRegTypeCouple}
                        text={`${discounts[0]?.discountUsed?.value / 100} SEK`}
                      />
                    </Discount>
                  </Box>
                  <Box fullWidth align="flex-end" top spacing="6px">
                    <FigmaText
                      textKey={Texts.componentsKpIsKpiSecondary}
                      text={activity?.price ? `${discounts[0]?.discountedPrice / 100} SEK` : ''}
                    />
                  </Box>
                </>
              )}
            </CourseContainer>
          </Box>
          {hasPartner && (
            <Box fullWidth top spacing="8px">
              <FigmaText textKey={Texts.componentsKpIsKpiSecondary} text={state?.partnerName} />
              <CourseContainer fullWidth fullPadding spacing="16px">
                <Box fullWidth direction="row" justify="space-between">
                  <FigmaText textKey={Texts.componentsKpIsKpiSecondary} text={activity?.name} />
                  <Box direction="row" gap="4px">
                    <FigmaText
                      textKey={Texts.componentsKpIsKpiSecondary}
                      text={String(
                        discounts.length >= 2 ? discounts[1]?.originalPrice / 100 : discounts[0]?.originalPrice / 100
                      )}
                    />
                    <FigmaText textKey={Texts.componentsKpIsKpiSecondary} text="SEK" />
                  </Box>
                </Box>
                {shouldShowPartnerDiscount && (
                  <>
                    <Box fullWidth direction="row" justify="space-between" top spacing="8px">
                      <FigmaText textKey={Texts.componentsPricingControlCoupleDiscountRow} />
                      <Discount fullPadding spacing="2px">
                        <FigmaText
                          textKey={Texts.codeTablesRegTypeCouple}
                          text={`${discounts[0]?.discountUsed?.value / 100} SEK`}
                        />
                      </Discount>
                    </Box>
                    <Box fullWidth align="flex-end" top spacing="6px">
                      <FigmaText
                        textKey={Texts.componentsKpIsKpiSecondary}
                        text={`${discounts[1]?.discountedPrice / 100} SEK`}
                      />
                    </Box>
                  </>
                )}
              </CourseContainer>
            </Box>
          )}
          <Box fullWidth direction="row" align="center" justify="space-between" top spacing="22px">
            <FigmaText textKey={Texts.componentsKpIsKpiPaidLabel} text="Total" />
            <FigmaText textKey={Texts.componentsKpIsKpiPaidLabel} text={finalPrice.toString() + ' SEK'} />
          </Box>
        </PriceContainer>
      </SelectedCourses>
      <Box alignSelf="flex-end" top>
        {!state.isRegistrationCompleted ? (
          <ContinueButton onClick={onNextStep}>
            <FigmaText textKey={Texts.registrationCtactaContinue} />
            <img src={iconArrow} width={16} height={16} />
          </ContinueButton>
        ) : null}
      </Box>
    </OpenContainer>
  )
}

const SelectedCourses = styled(Box)`
  gap: 24px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const ClosedContainer = styled(Box)`
  cursor: pointer;
  max-width: 865px;
  max-height: 70px;
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
`

const StepCircle = styled(Box)`
  width: 30px;
  height: 30px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  background-color: rgba(30, 56, 126, 1);
`

const OpenContainer = styled(Box)`
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
  box-shadow: 0px 0px 8px 0px #2422bb;
`

const PriceContainer = styled(Box)`
  width: 100%;
  background-color: #efefef;
  border-radius: 12px;
`

const CourseContainer = styled(Box)`
  border-radius: 12px;
  background-color: #fcfcfc;
  border: 1px solid #f5eded;
`

const Discount = styled(Box)`
  background-color: #70ab37;
`

const ContinueButton = styled.button`
  gap: 16px;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: rgba(59, 89, 236, 1);
`

export default CourseRegistrationStepTwo
