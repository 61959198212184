import styled from '@emotion/styled'
import { InputAdornment, TextFieldProps } from '@mui/material'
import React from 'react'
import { ControllerFieldState } from 'react-hook-form'
import { TextKey, TextKeyWithOnlyText } from '../../../pure-js/types/Antiloop'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText from './FigmaText'
import { TextFieldOutlined } from './TextFieldOutlined'
import { GenericErrorComponent } from './TextFieldForm'
export type ErrorComponentProps = { name: string; fieldState?: ControllerFieldState }

export type TextFieldFormProps = TextFieldProps & {
  name: string
  component?: React.FC
  labelTextKey?: TextKey
  labelText?: string
  placeholderTextKey?: TextKeyWithOnlyText
  adormentTextKey?: TextKey
  isMobile?: boolean
  ErrorComponent?: React.FC<any>
  isWhite?: boolean
  autoFocus?: boolean
  rows?: number
  InputProps?: any
  fullWidth?: boolean
  fieldState: ControllerFieldState
}

function TextFieldFormComponent(
  {
    name,
    fullWidth,
    placeholderTextKey,
    adormentTextKey,
    onChange,
    value,
    onBlur,
    className,
    defaultValue,
    children,
    select,
    type,
    onFocus,
    disabled,
    placeholder,
    onKeyDown,
    multiline,
    rows,
    autoFocus = false,
    InputProps,
    ErrorComponent = GenericErrorComponent,
    fieldState
  }: TextFieldFormProps,
  ref
) {
  return (
    <StyledWrapper fullWidth={fullWidth}>
      <TextFieldOutlined
        select={select}
        size="small"
        fullWidth={fullWidth}
        value={value}
        className={className}
        disabled={disabled}
        placeholder={placeholder || (!!placeholderTextKey && !value && getFigmaText(placeholderTextKey)) || ''}
        InputProps={
          InputProps || {
            endAdornment: !!adormentTextKey && (
              <InputAdornment position="end">
                <FigmaText textKey={adormentTextKey} />
              </InputAdornment>
            )
          }
        }
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
        name={name}
        type={type}
        onKeyDown={onKeyDown}
        multiline={multiline}
        onFocus={onFocus}
        autoFocus={autoFocus}
        rows={rows}
      >
        {children}
      </TextFieldOutlined>
      {!!fieldState?.error && <ErrorComponent name={name} fieldState={fieldState} />}
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Box)<{ fullWidth?: boolean }>`
  ${(props) => props.fullWidth && 'width: 100%;'}
`

export default React.forwardRef(TextFieldFormComponent)

export function getError(formProps: any, name: any) {
  return !!formProps.errors
}
